import { type CurrentUserQuery } from "@wpf-admin-ui/gql/generated/graphql";
import { type OperationResult } from "urql";

const ServerErrorCode = Object.freeze({
  NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
});

interface ServerError {
  message: string;
  code: string;
}

const parseServerErrors = (
  result: Pick<OperationResult<CurrentUserQuery, Record<string, unknown>>, "error">,
): ServerError[] => {
  const errors = result.error?.graphQLErrors ?? [];
  return errors.map((e) => ({
    code: e.extensions.code,
    message: e.message,
  }));
};

export const responseContainsAuthZError = (
  result: OperationResult<CurrentUserQuery, Record<string, unknown>>,
): boolean => {
  const errors = parseServerErrors(result);
  return errors.some((e) => ([ServerErrorCode.NOT_AUTHORIZED] as string[]).includes(e.code));
};

export const responseContainsAuthNError = (
  result: Pick<OperationResult<CurrentUserQuery, Record<string, unknown>>, "error">,
): boolean => {
  const errors = parseServerErrors(result);
  return errors.some((e) => ([ServerErrorCode.NOT_AUTHENTICATED] as string[]).includes(e.code));
};
